import NestedFormController from "../nested_form_controller";

export default class extends NestedFormController {
  static targets = ["links", "template", "selectbox"];

  createRecords(event) {
    const { options, dates } = event.detail;
    const optionKeys = Object.keys(options);
    const optionValues = Object.values(options);
    dates.forEach((date) => {
      optionKeys.forEach((key) => {
        const content = this.templateTarget.innerHTML.replace(
          /NEW_RECORD/g,
          new Date().getTime(),
        );
        const wrapper = document.createElement("div");
        wrapper.innerHTML = content;
        const { variableName } = wrapper.firstElementChild.dataset;
        super.setDateField(wrapper, date, "date");
        const valueString = optionValues.join(", ");
        const inputField = wrapper.querySelector(`input[name*="${key}"]`);
        inputField.value = valueString;
        wrapper
          .querySelectorAll(`[name^="${variableName}"]`)
          .forEach((field) => {
            const newName = field.name.replace(
              new RegExp(`${variableName}\\[\\d+\\]`),
              `${variableName}[${new Date().getTime()}]`,
            );
            field.name = newName;
            field.id = field.id.replace(/\d+/, new Date().getTime());
          });

        this.linksTarget.insertAdjacentElement(
          "beforebegin",
          wrapper.firstElementChild,
        );
      });
    });

    super.rebindUI();
  }
}
