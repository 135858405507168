import { Controller } from "@hotwired/stimulus";
import { initStartEndDateFields } from "../src/custom/DatetimeInit";

export default class extends Controller {
  static targets = ["startDate", "endDate"];

  connect() {
    if (this.startDateTarget && this.endDateTarget) {
      initStartEndDateFields(
        `#${this.startDateTarget.id}`,
        `#${this.endDateTarget.id}`,
        this.startDateTarget.dataset.startEndDateFormatted === "true",
      );
    }
  }
}
