import FlowbitePopoverController from "./flowbite_popover_controller";
import { isHidden } from "../../src/custom/Utils";

export default class extends FlowbitePopoverController {
  popoverOptions = {
    ...super.popoverOptions,
    placement: "top",
    triggerType: "hover",
  };

  connect() {
    super.connect();
    // The popovers hide/show through visibility which does not remove the element from the layout so it shows as a gap during page load.
    // The hidden class was added to the popover element to hide it during page load.
    // This removes the hidden class once connected and prevents page load flash.
    if (isHidden(this.popoverTarget)) {
      this.popoverTarget.classList.remove("tw-hidden");
    }
  }
}
