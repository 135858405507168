import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  change() {
    if (this.element.checked) {
      if (this.element.dataset.hideField) {
        document.getElementById(this.element.dataset.hideField).classList.add("tw-hidden");
      }

      if (this.element.dataset.showField) {
        document.getElementById(this.element.dataset.showField).classList.remove("tw-hidden");
      }
    } else {
      if (this.element.dataset.showField) {
        document.getElementById(this.element.dataset.showField).classList.add("tw-hidden");
      }

      if (this.element.dataset.hideField) {
        document.getElementById(this.element.dataset.hideField).classList.remove("tw-hidden");
      }
    }
  }
}
