import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file", "preview"];

  preview() {
    const file = this.fileTarget.files[0];

    if (this.hasPreviewTarget && file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
        this.previewTarget.classList.remove("tw-hidden");
      };

      reader.readAsDataURL(file);
    }
  }
}
