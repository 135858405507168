import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  toggle = () => {
    this.fieldTargets.forEach((field) => {
      field.disabled = !field.disabled;
    });
  };
}
